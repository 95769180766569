<template>
  <div class="home-activity">


    <banner-swiper
        :banner="advertise.banner"
        :textinfo="textinfo"
    >

    </banner-swiper>




    <div class="home-activity-content">
        <course/>
        <!-- <activity v-if="activityTab == 2"/> -->
    </div>
  </div>
</template>

<script>
import course from './course.vue'
import activity from './activity.vue'
import {getAdvertiseList} from "@/api/advertise";
import VImg from "@/components/VImg";
import BannerSwiper from "@/components/BannerSwiper";

export default {
  components:{
    course,
    activity,
    VImg,
    'banner-swiper':BannerSwiper
  },
  data() {
    return {
      // activityTab: 1,
      advertise:{
        banner:[],
        notif:'',
      },
      textinfo:{
        title:'鸿联云培训课程',
        text:'定制、专业、实战化的教培课程体系',
        type:'activity',
        height:280,
      }
    };
  },
  mounted(){
    if(this.$route.params.type){
      this.activityTab = this.$route.params.type
    }
    this.loadGetAdvertise();
  },

  methods:{
    loadGetAdvertise(){
      let params={
        adType:'ACTIVITY_COURSE_BANNER',
        validStatus:'1',
      }

      getAdvertiseList(params).then((res)=>{
        console.log(res,'====广告====')
        let data=res.records
        data.forEach(item=>{
          if(item.adType==='ACTIVITY_COURSE_BANNER' && item.validStatus==1){
            this.advertise.banner.push(item)
          }
        })

        console.log(this.advertise,'advertise')


      })
    },



  },
};
</script>

<style lang="scss" scoped>
.home-activity {


  .home-activity-tab {
    padding: 8px 0;
    background: #fff;
    display: flex;
    justify-content: center;
    > span {
      height: 48px;
      line-height: 48px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 18px;
      &:first-child {
        margin-right: 200px;
      }
      &.active {
        border-color: #EA0B06;
      }
    }
  }
  .home-activity-content{
      width: 1280px;
      margin: 0 auto;
      padding: 24px 0 56px;
  }
}
</style>
